var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',{staticClass:"header_row"},[_c('b-col',[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("Report by Person"))+" "),_c('b-link',{attrs:{"to":{ name: 'Reports' }}},[_c('b-icon-arrow-return-left')],1)],1),_c('b-breadcrumb',{attrs:{"items":_vm.br_items}}),_c('h2',[(_vm.has_info)?_c('span',[_vm._v(" "+_vm._s(_vm.repr)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("All"))+" ")]),_c('b-link',{on:{"click":function($event){_vm.show_modal = true}}},[_c('b-icon-gear')],1)],1),_c('select-person-or-operator',{attrs:{"options":_vm.persons_items,"value-field":"pk","text-field":"_name"},model:{value:(_vm.selected_info),callback:function ($$v) {_vm.selected_info=$$v},expression:"selected_info"}})],1),_c('b-modal',{attrs:{"hide-footer":"","hide-header":""},model:{value:(_vm.show_modal),callback:function ($$v) {_vm.show_modal=$$v},expression:"show_modal"}},[_c('report-by-person-form',{on:{"ok":_vm.go_to}})],1)],1),_c('b-overlay',{attrs:{"show":_vm.loading}},[(!_vm.is_empty && !_vm.loading)?_c('b-row',{staticClass:"header_row"},[_c('b-col',[_c('b-table-simple',[_c('b-tr',{staticClass:"green-bg"},[_c('b-th',{staticStyle:{"text-wrap":"none"},attrs:{"colspan":_vm.colspan_max * 1 + 1}},[_vm._v("Report HC results")])],1),_c('b-tr',[_c('b-th',{staticClass:"green-bg"},[_vm._v("Health-Check")]),_vm._l((_vm.colspan_max),function(i){return _c('th',{key:i,staticClass:"lightgreen-bg"},[_vm._v(" Date & Result ")])})],2),_vm._l((_vm.grouped),function(quizes,key){return _c('div',{directives:[{name:"frag",rawName:"v-frag"}],key:key},[_c('b-tr',[_c('b-th',{staticClass:"green-bg",attrs:{"rowspan":"2"}},[_vm._v(_vm._s(_vm.get_cat_name(key)))]),_vm._l((quizes),function(quiz){return _c('b-td',{key:quiz.pk,staticClass:"date-cell"},[_c('b-link',{attrs:{"to":{
                    name: 'HCDReport',
                    query: {
                      category: key,
                      start: _vm.date_frmt(quiz.date),
                      period: 'day',
                      info: _vm.$route.query.info || _vm.selected_info,
                    },
                  }}},[_vm._v(" "+_vm._s(_vm._f("date_format")(quiz.date))+" ")])],1)})],2),_c('b-tr',_vm._l((quizes),function(quiz){return _c('b-td',{key:quiz.pk},[_vm._v(_vm._s(parseInt(quiz.p_score))+" %")])}),1)],1)})],2)],1)],1):_c('b-row',{staticClass:"header_row"},[_c('b-col',[_c('h2',[_vm._v(_vm._s(_vm.$t("Nothing found")))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }