<template>
  <b-container>
    <b-row class="header_row">
      <b-col>
        <h1>
          {{ $t("Report by Person") }}
          <b-link :to="{ name: 'Reports' }">
            <b-icon-arrow-return-left />
          </b-link>
        </h1>
        <b-breadcrumb :items="br_items" />
        <h2>
          <span v-if="has_info">
            {{ repr }}
          </span>
          <span v-else> {{ $t("All") }} </span>
          <b-link @click="show_modal = true">
            <b-icon-gear />
          </b-link>
          <!--b-link > <b-icon-download />  </b-lin!-->
        </h2>
        <select-person-or-operator
          v-model="selected_info"
          :options="persons_items"
          value-field="pk"
          text-field="_name"
        />
      </b-col>
      <b-modal hide-footer hide-header v-model="show_modal">
        <report-by-person-form @ok="go_to" />
      </b-modal>
    </b-row>
    <b-overlay :show="loading">
      <b-row v-if="!is_empty && !loading" class="header_row">
        <b-col>
          <b-table-simple>
            <b-tr class="green-bg">
              <b-th :colspan="colspan_max * 1 + 1" style="text-wrap: none"
                >Report HC results</b-th
              >
            </b-tr>
            <b-tr>
              <b-th class="green-bg">Health-Check</b-th>
              <th v-for="i in colspan_max" :key="i" class="lightgreen-bg">
                Date & Result
              </th>
            </b-tr>
            <!--            <fragment v-for="quizes, key in grouped" :key="key">-->
            <div v-for="(quizes, key) in grouped" :key="key" v-frag>
              <b-tr>
                <b-th rowspan="2" class="green-bg">{{
                  get_cat_name(key)
                }}</b-th>
                <b-td v-for="quiz in quizes" :key="quiz.pk" class="date-cell">
                  <b-link
                    :to="{
                      name: 'HCDReport',
                      query: {
                        category: key,
                        start: date_frmt(quiz.date),
                        period: 'day',
                        info: $route.query.info || selected_info,
                      },
                    }"
                  >
                    {{ quiz.date | date_format }}
                  </b-link>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td v-for="quiz in quizes" :key="quiz.pk"
                  >{{ parseInt(quiz.p_score) }} %</b-td
                >
              </b-tr>
            </div>
            <!--            </fragment>-->
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row v-else class="header_row">
        <b-col>
          <h2>{{ $t("Nothing found") }}</h2>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>

<script>
import { groupBy, merge, flatten, uniqWith, isEqual } from "lodash-es";
//import {Fragment} from 'vue-fragment'
import { format } from "date-fns";
import ReportByPersonForm from "../components/ReportByPersonForm";
import { dict_to_search, to_query } from "../shared";
import frag from "vue-frag";
import SelectPersonOrOperator from "../components/SelectPersonOrOperator";
import { SERVER } from "../constants";

const date_format = (value) =>
  value ? format(value, "yyyy-LL-dd") : "Set value";

export default {
  name: "ReportByPerson",
  components: { SelectPersonOrOperator, ReportByPersonForm },
  filters: {
    date_format,
  },
  directives: { frag },
  mounted() {
    this.load();
  },
  data() {
    return {
      loading: false,
      resp: [],
      selected_info: null,
      show_modal: false,
    };
  },
  computed: {
    has_info() {
      return !!this.$route.query.info;
    },
    persons_items() {
      let ret = [];
      if (!this.has_info) {
        if (this.resp.length) {
          const items = this.$store.getters.additional_info
            .filter((i) => ["person", "operator"].indexOf(i.type) >= 0)
            .reduce((r, i) => ({ ...r, [i.pk]: i }), {});
          ret = flatten(this.resp.map((r) => r.info)).filter(
            (i) => ["person", "operator"].indexOf(i.type) >= 0
          );
          ret = uniqWith(ret, isEqual)
            .map((a) => ({ ...a, ...items[a.pk] }))
            .map((a) => ({
              ...a,
              _name: `${a.type}: ${a._name}`,
            }));
        }
      }
      return ret;
    },
    br_items() {
      return [
        { text: this.$t("Home"), to: { name: "Home" } },
        { text: this.$t("Reports"), to: { name: "Reports" } },
        { text: this.$t("Report by Person"), active: true },
      ];
    },
    repr() {
      let ret = [];
      const { info } = this.$route.query;
      if (info) {
        let o = this.$store.getters.additional_info.find((p) => p.pk == info);
        if (o) {
          ret.push(o.name);
        }
      }
      return `Report for ${ret.join(", ")}`;
    },
    is_empty() {
      return this.resp.length === 0;
    },
    category_list() {
      return this.$store.getters.category;
    },
    restored() {
      let ret = this.resp.map((q) =>
        merge(q, {
          date: new Date(q.date),
        })
      );
      if (!this.has_info && this.selected_info) {
        ret = ret.filter(
          (r) =>
            r.info
              .map((i) => parseInt(i.pk))
              .indexOf(parseInt(this.selected_info)) > -1
        );
      }
      return ret;
    },
    grouped() {
      return groupBy(this.restored, (i) => parseInt(i.category));
    },
    colspan_max() {
      return Math.max(
        ...Object.values(this.grouped).map((quizes) => quizes.length)
      );
    },
  },
  methods: {
    date_frmt: date_format,
    load() {
      let req = new URLSearchParams(this.$route.query);
      this.loading = true;
      Promise.all([
        this.$store.dispatch("load_data", [
          "category",
          "additional_info",
          "question",
        ]),
        this.$store
          .dispatch("get", {
            url: `api/reports/by_person/?${req}`,
          })
          .then((r) => (this.resp = r)),
      ]).then(() => {
        this.loading = false;
        if (this.persons_items.length) {
          this.selected_info = this.persons_items[0].pk;
        }
      });
    },
    go_to(args) {
      this.show_modal = false;
      this.$router.push({ name: this.$route.name, query: to_query(args) });
      this.load();
    },
    get_cat_name(id) {
      const obj = this.category_list.find((c) => c.id == id);
      let ret = "";
      if (obj) {
        ret = obj.name;
      }
      return ret;
    },
    build_url(xls = false, with_server = false) {
      let q_data = merge({}, this.$route.query);
      if (xls) {
        q_data["frmt"] = "xlsx";
      }
      let query_str = dict_to_search(q_data);
      let ret = `api/reports/by_person/?${query_str}`;
      if (with_server) {
        ret = `${SERVER}/${ret}`;
      }
      return ret;
    },
  },
};
</script>

<style scoped>
.date-cell {
  white-space: nowrap;
}

div.comments {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20em;
  max-height: 3em;
}
</style>
